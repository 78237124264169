<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="addBorrowFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="5">
          <el-form-item label="存款编码" width="120px">
            <el-select v-model="bankCodeList" filterable placeholder="请选择" @change="getCode">
              <el-option
                v-for="item in bankOption"
                :key="item.id"
                :label="item.subbranch"
                :value="{label:item.bankCode,value:item.id,text:item.subbranch,depositedIncomeMoney:item.depositedIncomeMoney}"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="2">{{this.bankCodeList.label}}</el-col>
        <el-col :span="7">
          <el-form-item label="借走日期" width="120px">
            <el-date-picker
              v-model="addBorrowFrom.borrowData"
              align="right"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="是否一定归还" width="120px" prop="isMustBack">
            <el-radio v-model="addBorrowFrom.isMustBack" label="1">是</el-radio>
            <el-radio v-model="addBorrowFrom.isMustBack" label="2">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="借走人姓名" width="120px">
            <el-input width="20px" v-model="addBorrowFrom.borrowName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="借走人别名" width="120px">
            <el-input width="20px" v-model="addBorrowFrom.borrowAlias"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="预计归还日期" width="120px">
            <el-input width="20px" v-model="addBorrowFrom.preReturnData"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="存款金额" width="120px">
            <el-input-number width="20px" v-model="addBorrowFrom.depositedIncomeMoney" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="借走金额" width="120px">
            <el-input-number
              width="20px"
              v-model="addBorrowFrom.borrowMoney"
              @change="changeMoney"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="借走后金额" width="120px">
            <el-input width="20px" v-model="addBorrowFrom.newDepositedIncomeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="21">
          <el-form-item label="借走备注" width="120px">
            <el-input width="20px" v-model="addBorrowFrom.borrowRemark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 附件问题 暂时不处理 -->
    <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="附件" name="first">用户管理</el-tab-pane>
    </el-tabs>-->
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addBorrrowButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { selectBankInfoList } from "@/api/capital/bank/bank";
import { addBorrow, selectBorrowById,editBorrow } from "@/api/capital/bank/bankBorrow";
export default {
  name: "AddBorrrow",
  data() {
    return {
      dialogVisible: false,
      addBorrowFrom: {
        borrowData: null,
        isMustBack: "1"
      },
      activeName: "first",
      querms: {
        bankType: 2
      },
      bankCodeList: {
        text: null
      },
      bankOption: [],
      editDist: false,
      id: null,
      type: null
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    //打开弹窗
    show(id, type) {
      this.dialogVisible = true;
      this.id = id;
      this.type = type;
      if (type == 1) {
        this.selectBankInfoListButton();
      }
      if (type == 2) {
        this.editDist = true;
        this.selectByIdButton();
      }
    },
    selectByIdButton() {
      selectBorrowById(this.id).then(res => {
        this.addBorrowFrom = res.result;
      });
    },
    selectBankInfoListButton() {
      selectBankInfoList(this.querms).then(res => {
        this.bankOption = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    addBorrrowButton() {
      this.addBorrowFrom.isMustBack = parseInt(this.addBorrowFrom.isMustBack);
      this.addBorrowFrom.bankInfoId = this.bankCodeList.value;
      var addBankBorrowFrom = {
        addBankBorrowVO: this.addBorrowFrom
      };
      if (this.type === 1) {
        addBorrow(addBankBorrowFrom).then(res => {
          if (res.code !== '200') return this.$message.error(res.message);
          this.dialogVisible = false;
          this.$parent.getBankBorrowButton();
          this.$message.success(res.message);
        });
      }
      if (this.type === 2) {
        editBorrow(addBankBorrowFrom).then(res => {
          if (res.code !== '200') return this.$message.error(res.message);
          this.dialogVisible = false;
          this.$parent.getBankBorrowButton();
          this.$message.success(res.message);
        });
      }
    },
    getCode() {
      this.addBorrowFrom.depositedIncomeMoney = this.bankCodeList.depositedIncomeMoney;
    },
    changeMoney() {
      this.addBorrowFrom.newDepositedIncomeMoney =
        this.addBorrowFrom.depositedIncomeMoney -
        this.addBorrowFrom.borrowMoney;
    }
  }
};
</script>